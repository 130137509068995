<style lang="scss" scoped>
::v-deep .checkbox {
	margin: 0;
	align-items: flex-start;
	.van-checkbox__icon {
		position: relative;
		top: 0.06rem;
		border-radius: 0.06rem;
		.van-icon {
			border-radius: 0.06rem;
		}
		padding-right: 0.2rem;
	}
	.van-checkbox__label {
		font-size: 0.24rem;
		line-height: 0.36rem;
		color: $font_color_thd;
		margin-left: 0;
	}
}
.is_link {
	color: $color_main;
}

::v-deep .must_read_pop {
	.van-action-sheet__content {
		overflow: hidden;
	}
}
.must_read_content {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 0 0.3rem 0.3rem;
	height: 60vh;
	box-sizing: border-box;
	.content_info {
		height: 41vh;
		overflow-y: scroll;
		word-break: break-all;
	}

	.content {
		flex: 1;
		overflow-y: auto;
	}
	.loading_btn_box {
		width: 100%;
		padding: 0.2rem 0.3rem;
		box-sizing: border-box;
		.loading_btn {
			border-radius: 8px;
			height: 0.8rem;
		}
	}
}
.must_read_pop {
	z-index: 3001 !important;
	.loading_btn_content {
		display: flex;
		align-items: center;
		.time {
			color: #fff;
			margin-left: 0.4rem;
		}
	}
}
.clause_title {
	font-size: 0.28rem;
	color: $color_main;
	margin: 0.3rem 0 0.1rem;
	font-weight: bold;
}
.clause_text {
	font-size: 0.24rem;
	padding: 0.1rem;
	a {
		color: $font_color_main;
	}
}
.mb_2 {
	margin-bottom: 0.2rem;
}
.other_tip{
	background-color: #cee4f8;
	color: $color_main;
	font-size: 0.26rem;
	border-radius: 0.2rem;
	padding: 0.2rem;
	margin-top: 0.3rem;
}
.insurance_tip{
	font-size: 0.24rem;
	.hint_title{
		font-weight: bold;
		padding: 10px 0;
	}
}
</style>

<template>
	<div class="clause_info">
		<div class="card_box">
			<van-checkbox class="checkbox mb_2" v-model="rule_1" @change="checkChange" shape="square" icon-size="0.28rem" label-disabled>
				<div class="flex_normal">
					<span @click="rule_1 = !rule_1">请仔细阅读并同意</span>
					<span @click="handleOpen">
						<span class="is_link per_rule" v-text="$t('insureHint')">《投保提示》</span>
						<span class="is_link" v-text="$t('liabilityContent')">《免责内容》</span>
						<span class="is_link" v-text="$t('insuredAgrees')">《被保险人同意声明》</span>
						<span class="is_link" v-text="$t('insuranceClause')">《保险条款》</span>
						<span class="is_link" v-text="$t('insuranceDeclaration')">《投保声明》</span>
						<span class="is_link per_rule" v-text="$t('userInfoProtect')">《个人信息保护政策》</span>
					</span>
				</div>
			</van-checkbox>
		</div>
		<bottom-info-pop v-model="infoPopShow" :title="infoPopTitle" :content="infoPopContent"></bottom-info-pop>

		<van-action-sheet v-model="mustReadPopShow" class="must_read_pop" title="请仔细阅读以下内容">
			<div class="must_read_content">
				<div class="tabs">
					<van-tabs v-model="mustReadIndex" color="#409EFF" title-active-color="#409EFF">
						<van-tab class="text" title="投保提示" :disabled="isReading && !isReadList.includes(0)">
							<div class="content_box">
								
								<div class="content_info" >
									<div class="other_tip">
										耀保网消费者权益保护温馨提示：请仔细阅读保险条款、投保须知等内容，以保障自身的权益
									</div>
									<div class="insurance_tip" v-if="Array.isArray(information.insuranceTip)">
											<div v-for="(item,index) in information.insuranceTip" :key="index">
												<div class="hint_title" v-html="item.title"></div>
												<div v-html="item.content"></div>
											</div>
									</div>
									<div v-else v-html="information.insuranceTip"></div>
								</div>
								<van-button style="width: 100%;border-radius: 8px;" type="info" @click="nextDoc">{{ $t('iagree') }}{{ $t('insurePrompt') }}</van-button>
							</div>
						</van-tab>
						<van-tab title="免责内容" :disabled="isReading && !isReadList.includes(1)">
							<div class="content_info" v-html="information.preventDuty"></div>
							<van-button style="width: 100%;border-radius: 8px;" type="info" @click="nextDoc">{{ $t('iagree') }}{{ $t('liabilityContent') }}</van-button>
						</van-tab>
						<van-tab title="被保险人同意声明" :disabled="isReading && !isReadList.includes(2)">
							<div class="content_info" v-html="information.insuredDeclare"></div>
							<van-button style="width: 100%;border-radius: 8px;" type="info" @click="nextDoc">{{ $t('iagree') }}{{ $t('insuredAgrees') }}</van-button>
						</van-tab>
						<van-tab title="保险条款" :disabled="isReading && !isReadList.includes(3)">
							<div class="content_info">
								<div class="clause_title">主条款</div>
								<div class="clause_text" v-for="item in insuranceClause.mainClausesList" :key="item.docLink">
									<a :href="insuranceClause.productFileServer + item.docLink">{{ item.docName }}</a>
								</div>
								<div class="clause_title">附加条款</div>
								<div class="clause_text" v-for="item in insuranceClause.additionClausesList" :key="item.docLink">
									<a :href="insuranceClause.productFileServer + item.docLink">{{ item.docName }}</a>
								</div>
							</div>
							<van-button @click="nextDoc" style="width: 100%;border-radius: 8px;" type="info">{{ $t('iagree') }}{{ $t('insuranceClause') }}</van-button>
						</van-tab>
						<van-tab title="投保声明" :disabled="isReading && !isReadList.includes(3)">
							<div class="content_info" v-html="insuranceDeclaration"></div>
							<van-button @click="nextDoc" style="width: 100%;border-radius: 8px;" type="info">{{ $t('iagree') }}{{ $t('insuranceDeclaration') }}</van-button>
						</van-tab>
						<van-tab title="个人信息保护政策" :disabled="isReading && !isReadList.includes(3)">
							<div class="content_info" v-html="userInfoProtect"></div>
							<van-button @click="nextDoc" style="width: 100%;border-radius: 8px;" type="info">{{ $t('iagree') }}{{ $t('userInfoProtect') }}</van-button>
						</van-tab>
					</van-tabs>
				</div>
			</div>
		</van-action-sheet>
	</div>
</template>

<script>
import { Checkbox, ActionSheet, Button, CountDown, Toast, Tab, Tabs } from 'vant';
import { http_insDatingBack } from '@/request/common';
import { fixedInfo, porductClause } from '@/request/api';

export default {
	name: 'clauseInfo', // 条款信息
	components: {
		[Checkbox.name]: Checkbox,
		[ActionSheet.name]: ActionSheet,
		[Button.name]: Button,
		[CountDown.name]: CountDown,
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		[Toast.name]: Toast,
	},
	props: {
		proInfo: {
			type: Object,
			required: true,
		},
		information: {
			type: Object,
			required: true,
		},
		planId: {
			type: [String, Number],
			required: true,
		},
		proId: {
			type: [String, Number],
			required: true,
		},
	},
	data() {
		return {
			// 条款
			rule_1: false,
			// 投保声明、个人信息保护政策内容
			infoPopShow: false,
			infoPopTitle: '',
			infoPopContent: '',
			mustReadPopShow: false,
			mustReadIndex: 0,
			isReading: true,
			isFirst: false,
			insuranceClause: {}, //条款
			insuranceDeclaration: '', //投保声明
			userInfoProtect: '', //个人信息保护政策
			isReadList: [0],
		};
	},
	created() {
		this.getInsuranceClause();
		this.getRules();
	},

	methods: {
		getInsuranceClause() {
			porductClause(this.proId).then(res => {
				this.insuranceClause = res;
			});
		},
		getResult() {
			if (!this.rule_1) {
				Toast('请先勾选条款');
			}
			return this.rule_1;
		},

		// 获取投保声明、个人信息保护政策
		getRules() {
			fixedInfo().then(res => {
				res.fixedInfo?.forEach(dict => {
					if (dict.typeDesc === '投保声明') {
						this.insuranceDeclaration = dict.fixedInfo;
					} else if (dict.typeDesc === '个人信息保护政策') {
						this.userInfoProtect = dict.fixedInfo;
					}
				});
			});
		},

		nextDoc() {
			if (this.mustReadIndex < 3) {
				if (!this.isReadList.includes(this.mustReadIndex + 1)) {
					this.isReadList.push(this.mustReadIndex + 1);
				}
				this.$nextTick(() => {
					this.mustReadIndex = this.mustReadIndex + 1;
				});
				this.isReading = true;
			} else {
				this.isReading = false;
				this.mustReadPopShow = false;
				this.mustReadIndex = 0;
				this.rule_1 = true;
			}
		},

		finish() {
			this.isReading = false;
		},
		handleOpen() {
			this.mustReadPopShow = true;
			this.mustReadIndex = 0;
		},

		// 勾选改变
		checkChange() {
			if (this.isReading) {
				this.rule_1 = false;
				this.mustReadPopShow = true;
			} else {
				if (this.rule_1) {
					http_insDatingBack({ type: 6, content: '已阅读并同意所有条款', proId: this.proInfo.id });
				}
			}
		},
	},
};
</script>
