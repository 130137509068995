<style lang="scss" scoped>
::v-deep .van-cell {
	border: 0;
	&.van-cell::after {
		border: 0;
	}
}
.content_text {
	::v-deep .van-cell {
		padding: 0.2rem 0;
		border-bottom: 0.02rem solid #eeeff0;

		&:last-child {
			border-bottom: 0;
		}
	}
}

::v-deep .cell_title {
	color: $font_color_sec;
	font-size: 0.24rem;
}
::v-deep .cell_value {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-field__body input {
	color: $font_color_val;
}
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}

::v-deep .van-collapse-item__title {
	padding-left: 0;
}
::v-deep .van-collapse-item__content {
	padding-left: 0;
	padding-right: 0;
}
.card_box {
	box-shadow: 0rem 0.04rem 0.16rem 0rem rgba(0, 0, 0, 0.2);
	border-radius: 0.2rem 0.2rem 0.2rem 0.2rem;
	padding: 0 0.2rem;
	background-color: #fff;

	.new_main_title {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 0.28rem;
		font-weight: 600;
	}
	.line_title {
		width: 0.08rem;
		height: 0.32rem;
		background: $b_color_main;
		margin-right: 0.2rem;
	}
}
</style>

<template>
	<div class="other_info card_box">
		<van-collapse v-model="activeNames">
			<van-collapse-item name="1">
				<template #title>
					<div class="new_main_title">
						<div class="line_title"></div>
						<span>{{ $t('otherInfo') }}</span>
					</div>
				</template>
				<div class="content_text">
					<van-cell :title="$t('beneficiaryInformation')" :value="$t('statutoryBeneficiary')" title-class="cell_title" class="no_link main_row" value-class="cell_value" :border="false" is-link />
					<van-field :label="$t('emergencyContact')" input-align="right" class="no_link main_row" label-class="cell_title" value-class="cell_value" :border="false" is-link />
				</div>
			</van-collapse-item>
		</van-collapse>
	</div>
</template>

<script>
import { Collapse, CollapseItem, Cell, Field } from 'vant';

export default {
	name: 'otherInfo', // 其它信息
	components: {
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[Cell.name]: Cell,
		[Field.name]: Field,
	},
	data() {
		return {
			activeNames: ['0'],
		};
	},
};
</script>
